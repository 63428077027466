<template>

  <v-footer color="#0D111B" padless min-height="400">
    <v-row v-if="$vuetify.breakpoint.lgAndUp" justify="center" no-gutters class="pt-5">
      <v-col cols="5" lg="2" class="">
        <v-row>
          <v-col cols="12" class="text-left">
            <h4 class="text-left title pb-5">HELP</h4>
            <div class="tw-space-y-2">
              <div>
                <a class="text-left footer-text white--text" @click="TermnsConditions"> General Terms & Conditions</a>
              </div>
              <div>
                <a class="text-left footer-text white--text" @click="ResponsibleGamblingPolicy"> Responsible Gaming Policy</a>
              </div>
              <div>
                <a class="text-left footer-text white--text" @click="KycPolicy"> KYC Policy </a>
              </div>
              <div>
                <a class="text-left footer-text white--text" @click="PrivacyPolicy"> Privacy Policy</a>
              </div>
            </div>

          </v-col>
        </v-row>

      </v-col>
      <v-col cols="5" lg="2" class=" px-0">
        <h4 class="text-left title">OTHER CONTENTS</h4>
        <!-- <v-col class="text-left footer-text">Community Betting Website</v-col> -->
        <v-col class="text-left footer-text px-0"><a class="white--text"  @click="CasinoWebsite"> Casino Website</a></v-col>
      </v-col>
      <v-col cols="10" lg="2" class=" px-0">
        <h4 class="text-left title">LICENSE</h4>
        <!-- <v-col class="text-left footer-text license"
          >Copyright January 2023 Betgame.gg Gaming License No.625/JKO All Right
          Reserved.</v-col
        > -->
        <v-col class="text-left footer-text license"><div >
            <img :src="require('@/assets/images/license.svg')" alt="">
          </div></v-col>
      </v-col>
      <v-col cols="5" lg="4" class=" px-0">
        <v-col class="px-0">
          <v-img width="300" :src="require('@/assets/images/betgame-gg.svg')"></v-img>
        </v-col>
        <v-col class="pt-10">
          <v-row class="" align="center">
            <v-icon medium class="pr-5" color="white"> mdi-facebook </v-icon>
            <v-icon medium class="pr-5" color="white"> mdi-twitter </v-icon>
            <v-icon medium class="pr-5" color="white"> mdi-instagram </v-icon>
            <v-icon medium class="pr-5" color="white"> mdi-gmail </v-icon>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
    <v-row v-else justify="center" no-gutters>
      <v-col class="px-10 py-5">
        <v-row>
          <v-col cols="12" class="text-left">
            <div class="text-left title pb-3 text-body-1">HELP</div>
            <div class="tw-space-y-2">
              <div>
                <a class="text-left footer-text white--text text-caption" @click="TermnsConditions"> General Terms & Conditions</a>
              </div>
              <div>
                <a class="text-left footer-text white--text text-caption" @click="ResponsibleGamblingPolicy"> Responsible Gaming Policy</a>
              </div>
              <div>
                <a class="text-left footer-text white--text text-caption" @click="KycPolicy"> KYC Policy </a>
              </div>
              <div>
                <a class="text-left footer-text white--text text-caption" @click="PrivacyPolicy"> Privacy Policy</a>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <h4 class="text-left title text-body-1">OTHER CONTENTS</h4>
            <!-- <v-col class="text-left footer-text">Community Betting Website</v-col>
            <v-col class="text-left footer-text">Casino Website</v-col> -->
          </v-col>
          <v-col cols="12">
            <h4 class="text-left title text-body-1">LICENSE</h4>
            <!-- <v-col class="text-left footer-text license"
              >Copyright January 2023 Betgame.gg Gaming License No.625/JKO All Right
              Reserved.</v-col
            > -->
            <v-col class="text-left footer-text license"><a :href='licenseLink' target="_blank">
                <img :src="require('@/assets/images/license.svg')" alt="">
              </a></v-col>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-col class="px-0 py-0">
              <v-img width="250" :src="require('@/assets/images/betgame-gg.svg')"></v-img>
            </v-col>
            <v-col class="pt-5">
              <v-row class="" align="center">
                <v-icon medium class="pr-5" color="white"> mdi-facebook </v-icon>
                <v-icon medium class="pr-5" color="white"> mdi-twitter </v-icon>
                <v-icon medium class="pr-5" color="white"> mdi-instagram </v-icon>
                <v-icon medium class="pr-5" color="white"> mdi-gmail </v-icon>
              </v-row>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  computed: {
    licenseLink() {
      return process.env.VUE_APP_LICENSE_LINK;
    },
  },
  methods: {
    FrequentlyAskedQuestions() {
      if (this.$route.path != "/faqs") {
        this.$router.push("/faqs").catch(() => {});
      }
    },
    TermnsConditions() {
      if (this.$route.path != "/terms-conditions") {
        this.$router.push("/terms-conditions").catch(() => {});
      }
    },
    ResponsibleGamblingPolicy() {
      if (this.$route.path != "/responsible-gambling-policy") {
        this.$router.push("/responsible-gambling-policy").catch(() => {});
      }
    },
    KycPolicy() {
      if (this.$route.path != "/kyc-policy") {
        this.$router.push("/kyc-policy").catch(() => {});
      }
    },
    PrivacyPolicy() {
      if (this.$route.path != "/privacy-policy") {
        this.$router.push("/privacy-policy").catch(() => {});
      }
    },
    CasinoWebsite(){
      if (this.$route.path != "/casino") {
        this.$router.push("/casino").catch(() => {});
      }
    }
  },
};
</script>
<style scoped>
.license {
  word-break: break-word !important;
  width: 60% !important;
}
.title {
  font-family: "Avenir LT Pro";
  font-style: normal;
  font-weight: 750;
  font-size: 22px;
  line-height: 26px;
}
.footer-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
</style>