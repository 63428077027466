const state = {
  partnerlist: null,
  cashoutpartner: null,
  depositpartner: null,
  depositpartnertransactions: null,
  cashoutpartnertransactions: null,
  subpartners: null,
  partnerprofile: null,
  partnerdeposit: null,
  partnercashout: null,
  partnerdirectcashout: null,
  partnerdirectdeposit: null,
  agenttransactiondeposit: null,
  agenttransactioncashout: null,
  partnerpayouthistory: null,
  buycredits: null,
  sellcredits: null,
  dashboardtotalbydate: null,
  dashboardGraphTotal: null,
  adduser: null,
  partneruser: null,
  partnersubuser: null,
  transfertransaction: null,
  partnersubbuy: null,
  partnersubsell: null,
  agenttree: null,
  agenttransfertransaction: null,
  updatecommissionpercentage: null,
  agentinfo: null,
  partneragentlist: null,
  partnerpendingpayout: null,
  partnertransactions: null,
  error: null,
};

export default state;
