<template>
  <v-container class="deposit">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col class="pl-8">
          <v-col class="px-0">
            <v-card class="transfer-deposit" dark>
              <v-card-title class="transfer-deposit-text d-flex justify-center"
                >My Balance</v-card-title
              >
              <v-card-text class="transfer-deposit-text"
                >₱{{
                  partnerprofile && partnerprofile.partner_credits | formatMoney
                }}</v-card-text
              >
            </v-card>
          </v-col>
          <v-col class="px-0">
            <v-card class="transfer-deposit" dark>
              <v-card-title class="transfer-deposit-text d-flex justify-center"
                >My Total-Available</v-card-title
              >
              <v-card-text class="transfer-deposit-text"
                >₱{{ totalBalance | formatMoney }}</v-card-text
              >
            </v-card>
          </v-col>
        </v-col>
        <v-col class="pr-8">
          <v-col class="px-0">
            <v-card class="transfer-deposit" dark>
              <v-card-title class="transfer-deposit-text d-flex justify-center"
                >User Balance</v-card-title
              >
              <v-card-text class="transfer-deposit-text">&#8369;{{
                formatNumber(userBalance)
              }}</v-card-text>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="px-5">
        <v-col>
          <v-autocomplete
            clearable
            outlined
            v-model="selectPlayer"
            :items="players_list"
            label="Users"
            :rules="partnerUserRules"
            required
            rounded
            hide-details
            class="transfer-deposit-input"
            :no-filter="true"
            :item-text="combinedText"
            item-value="id"
            @keyup="searchPlayer"
            @click:clear="clearSeach"
          >
          
            <template v-slot:item="data" v-if="this.partnerprofile.type != 'agent'">
								<template>
										<v-row>
											<v-col cols="2" class="px-0" style="align-self: center;">
												<v-chip x-small :color="getColor(data.item.type)" class="tw-text-xs">{{getInitial(data.item.type)}}</v-chip>
											</v-col>
											<v-col cols="10" class="text-start">
                        <span class="tw-text-xs">
                          {{data.item.partner}} - {{data.item.name}}
                        </span>
                        <br>
                        <span class="tw-text-xs">
                         {{data.item.email}}
                        </span>
											</v-col>
										</v-row>
								</template>
							</template>
              <template v-slot:item="data" v-else>
								<template>
										<v-row>
											<v-col cols="2" class="px-0" style="align-self: center;">
												<v-chip x-small :color="getColor(data.item.type)" class="tw-text-xs">{{getInitial(data.item.type)}}</v-chip>
											</v-col>
											<v-col cols="10" class="text-start">
                        <span class="tw-text-xs">
                          {{data.item.name}}
                        </span>
                        <br>
                        <span class="tw-text-xs">
                         {{data.item.email}}
                        </span>
											</v-col>
										</v-row>
								</template>
							</template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            :rules="amountRules"
            label="Amount"
            v-model="amount"
            outlined
            required
            rounded
            class="text-field-amount"
          ></v-text-field>
        </v-col>
        <v-col class="pt-0" cols="12">
          <v-textarea
            v-model="comment"
            outlined
            name="input-7-4"
            label="Comment"
            class="transfer-deposit-input"
            hide-details
          ></v-textarea>
        </v-col>
      </v-row>
      <v-card-actions class="transfer-deposit-text d-flex justify-center py-10">
        <v-btn color="white" text @click="closeDialog"> CANCEL </v-btn>
        <v-btn color="#45D0D2" :disabled="isDisabled" rounded @click="openConfirmation" class="px-5">
          TRANSFER
        </v-btn>
      </v-card-actions>
        <v-dialog
				v-model="confirmDialog"
				@click:outside="closeDialog"
        @keydown.esc="closeDialog"
				height="400"
				width="400"
				>
					<v-card class="card">
						<v-col cols="12" class="pt-10 d-flex justify-center">
							<v-img max-height="75" max-width="75" :src="image"></v-img>
						</v-col>
						<v-col cols="12" class="d-flex justify-center pb-0">
							<v-card-title class="message-header font-weight-bold">
								ARE YOU SURE?
							</v-card-title>
						</v-col>
						<v-col cols="12" class="d-flex justify-center pt-0">
							<v-card-title class="message-header text-caption pt-0">
								<span class="text-wrap" style="width: 100%">Are you sure to</span>
								<span class="text-wrap" style="width: 100%"
								>proceed the Transaction?</span
								>
							</v-card-title>
						</v-col>
						<v-col cols="12" class="d-flex justify-center">
							<v-card-actions class="pb-10">
								<v-spacer></v-spacer>
								<v-btn
								class="dialog-btn"
								color="success"
								rounded
								@click="transferDeposit"
								>
								YES
								</v-btn>
								<v-btn
								class="dialog-btn"
								color="error"
								rounded
								@click="closeconfirmDialog"
								>
								NO
								</v-btn>
							</v-card-actions>
						</v-col>
					</v-card>
				</v-dialog>
    </v-form>
    <Alert
      :alert_dialog="alert_dialog"
      :alert_success="alert_success"
      :alert_message="alert_message"
      @closeAlertDialog="closeAlertDialog"
    />
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Alert from "../Alert.vue";
import numeral from "numeral" 
import Question from "@/assets/images/alert/question.svg";
import { useDebounceFn } from "@vueuse/core";


export default {
  props:["viewDialog"],
  data() {
    return {
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      selectPartnerUser: "",
      selectedUser: "",
      agentDetails: [],
      amount: "",
      alert_dialog: false,
      alert_success: "",
      alert_message: "",
      valid: true,
      comment: "",
      amountRules: [
        (amount) => !!amount || "Amount is required",
        (amount) => (amount && amount >= 100) || "Minimum of 100",
      ],
      partnerUserRules: [
        (selectPartnerUser) =>
          !!selectPartnerUser || "Transaction type is required",
      ],
      commentRules: [(comment) => !!comment || "Transaction type is required"],
      confirmDialog: false,
			image: Question,
      curPage: 1,
      default_item_per_page: 50,
      search: "",
      searchQuery: "",
      players_list : '',
      agent_players_list : [],
      selectPlayer: "",
      userBalance: "",
      // fetchDebounce: useDebounceFn(async () => {
      //     // console.log("RESULT LANG ",this.agent_players_list)

      //     await this.getPartnerSubUsers({
      //       search: this.selectPartnerUser || "",
      //       id: this.partnerprofile.id,
      //     });
      //   if (this.partnerprofile.type == "agent") {
      //     await this.getAllPlayers()
      //   } else {
      //     let subagent = this.adminpartnersubpartners && this.adminpartnersubpartners.map(data => {
      //       return data.user_id;
      //     });
      //     let tempUsers = [];
      //     let register_user = this.adminpartnersubpartners && this.adminpartnersubpartners.map(data => {
      //       data.registeredUsers.map(user => {
      //         let find_user = !subagent.includes(user.id.toString())
      //         if (find_user) {
      //           tempUsers.push({
      //             id: user.id,
      //             name: user.name.charAt(0).toUpperCase() + user.name.slice(1),
      //             partner: data.partner_name.charAt(0).toUpperCase() + data.partner_name.slice(1),
      //             type: data.type,
      //             email: user.email
      //           })
      //         }
      //       })
      //     });
      //     console.log("register_userregister_userregister_user",register_user)
      //     this.agent_players_list = tempUsers;
      //     if (!!this.selectPartnerUser) {
      //       let result = this.agent_players_list && this.agent_players_list.filter((agent_player) => agent_player.name.toLowerCase().includes(this.selectPartnerUser.toLowerCase()) || agent_player.email.toLowerCase().includes(this.selectPartnerUser.toLowerCase()))
      //       console.log("RESULT LANG ", result)
      //       this.agent_players_list = result
      //       await this.getAllPlayers()
      //     } else {
      //       await this.searchData()
      //       await this.getAllPlayers()
      //     }
      //   }
      // }, 500),
      fetchDebounce: useDebounceFn(async () => {
          // console.log("RESULT LANG ",this.agent_players_list)

          await this.getPartnerSubUsers({
            search: this.selectPartnerUser || "",
            id: this.partnerprofile.id,
          });
        if (this.partnerprofile.type == "agent") {
          await this.getAllPlayers()
        } else {
         let subplayer = this.partnersubuser && this.partnersubuser.map(data => {
           console.log("dataaaaa brian", data)
					return {
						id : data && data.id,
						name: data && data.name.charAt(0).toUpperCase() + data.name.slice(1),
            partner : data.registeredPartner.partner_name.charAt(0).toUpperCase() + data.registeredPartner.partner_name.slice(1),
						credits: data && data.credits,
						type: data && data.registeredPartner.type,
						email: data && data.email
					}
				});
				this.players_list = subplayer;
          if (!!this.selectPartnerUser) {
            let result = this.agent_players_list && this.agent_players_list.filter((agent_player) => agent_player.name.toLowerCase().includes(this.selectPartnerUser.toLowerCase()) || agent_player.email.toLowerCase().includes(this.selectPartnerUser.toLowerCase()))
            console.log("RESULT LANG ", result)
            this.agent_players_list = result
            await this.getAllPlayers()
          } else {
            await this.searchData()
            await this.getAllPlayers()
          }
        }
      }, 500),
      isDisabled : false
    };
  },
  components: {
    Alert,
  },
  computed: {
    ...mapState("partner", [
      "partnersubuser",
      "partnerprofile",
      "transfertransaction",
    ]),
    ...mapState("user", ["auth_user"]),
		...mapState("admin", ["adminpartnersubpartners"]),
    totalBalance() {
      let partner_credits =
        this.partnerprofile && this.partnerprofile.partner_credits;
      let total = partner_credits;
      return total;
    },
    combinedText() {
      return (item) => `${item.name} - ${item.email}`
    },
    combinedValue() {
      console.log("ITEM",item)
      // return (item) => item.id
    },
    filteredItems() {
      return this.items.filter(item => {
        const combined = `${item.name} ${item.email}`
        return combined.toLowerCase().includes(this.searchQuery.toLowerCase())
      })
    }
    
  },
  methods: {
    ...mapActions("partner", [
      "getPartnerSubUsers",
      "getPartnerProfileData",
      "postTransferTransaction",
      "getPartnerUserInfo"
    ]),
    ...mapActions("user", ["getUserInfo"]),
		...mapActions("admin", ["getPartnerSubPartners"]),
    closeDialog() {
      this.$emit("closeDialog", true);
    },
    async closeconfirmDialog(){
			this.isDisabled = false
			this.confirmDialog = false;
      await this.searchData()
      await this.getAllPlayers()
		},
    async transferDeposit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        await this.postTransferTransaction({
          user_id: this.selectPlayer,
          type: "deposit",
          amount: this.amount,
          partner_id: this.partnerprofile && this.partnerprofile.id,
          status: "completed",
          transaction_type: "direct",
        });
        if (this.transfertransaction.updated) {
          this.closeDialog();
          await this.getPartnerProfileData({
            id: this.$auth.user.id,
          });
          await this.getUserInfo(this.$auth.user.id);
					await this.getPartnerSubPartners({id : this.$cookies.get("partner_id")});
          await this.searchData();
          this.$refs.form.reset();
        }
        this.alert_dialog = true;
        this.alert_success = this.transfertransaction.updated;
        this.alert_message = !this.alert_success ? this.transfertransaction.message : "TRANSFER CREDITS SUCCESSFULLY";
      }
    },
    closeAlertDialog() {
      this.isDisabled = false
      this.alert_dialog = false;
      this.selectPartnerUser = ""
      this.searchData()
      this.getAllPlayers()
    },
    formatNumber(value){
      return numeral(value).format('0,0.00')
    },
    openConfirmation(){
			this.$refs.form.validate();
			if (this.$refs.form.validate()) {
        this.isDisabled = true
				this.confirmDialog = true
			}
		},
    async searchData(item) {
        await this.getPartnerSubUsers({
            search: this.search || "",
            id: this.$cookies.get("partner_id"),
        });
    },
    getInitial(type){
        if(type == 'partners')
            return 'MP';
        else if(type == 'area-manager')
            return 'AM';
        else if (type == "city-manager")
            return 'CM';
        else{
            return 'A';
        }
    },
    getColor(type){
        if(type == 'partners')
            return 'success';
        else if(type == 'area-manager')
            return 'warning';
        else if(type == "city-manager")
            return 'primary';
        else{
            return 'error';
        }
    },
    searchPlayer(e){
      this.selectPartnerUser = e.target.value
      this.fetchDebounce()
    },
    async getAllPlayers(){
      if (this.partnerprofile.type != 'partners' && this.partnerprofile.type != 'city-manager' && this.partnerprofile.type != 'area-manager') {
        let user =
          this.partnersubuser &&
          this.partnersubuser.map((data) => {
            return {
              id: data.id,
              name: data.name.charAt(0).toUpperCase() + data.name.slice(1),
              // partner : data.registeredPartner.partner_name.charAt(0).toUpperCase() + data.registeredPartner.partner_name.slice(1),
              // type : data.registeredPartner.type,
              email: data.email
            };
          });
        this.players_list = user;
      }else{
        let subplayer = this.partnersubuser && this.partnersubuser.map(data => {
					return {
						id : data && data.id,
						name: data && data.name.charAt(0).toUpperCase() + data.name.slice(1),
            partner : data.registeredPartner.partner_name.charAt(0).toUpperCase() + data.registeredPartner.partner_name.slice(1),
						credits: data && data.credits,
						type: data && data.registeredPartner.type,
						email: data && data.email
					}
				});
				this.players_list = subplayer;
        
      }
    },
    // async getAllPlayers(){
    //   if (this.partnerprofile.type != 'partners' && this.partnerprofile.type != 'city-manager' && this.partnerprofile.type != 'area-manager') {
    //     let user =
    //       this.partneruser &&
    //       this.partneruser.rows.map((data) => {
    //         return {
    //           id: data.id,
    //           name: data.name.charAt(0).toUpperCase() + data.name.slice(1),
    //           partner : data.registeredPartner.partner_name.charAt(0).toUpperCase() + data.registeredPartner.partner_name.slice(1),
    //           type : data.registeredPartner.type,
    //           email: data.email
    //         };
    //       });
    //     this.players_list = user;
    //     console.log("TEST1")
    //   }else{
    //     if (!this.selectPartnerUser) {
    //         let subagent = this.adminpartnersubpartners && this.adminpartnersubpartners.map(data => {
    //           return data.user_id;
    //         });
    //         let tempUsers = [] ;
    //         let register_user = this.adminpartnersubpartners && this.adminpartnersubpartners.map(data => {
    //           data.registeredUsers.map(user=>{
    //             let find_user = !subagent.includes(user.id.toString())
    //             if(find_user){
    //               tempUsers.push({
    //                 id: user.id,
    //                 name: user.name.charAt(0).toUpperCase() + user.name.slice(1),
    //                 partner : data.partner_name.charAt(0).toUpperCase() + data.partner_name.slice(1),
    //                 type : data.type,
    //                 email: user.email
    //               })
    //             }
    //           })
    //         });
    //         this.agent_players_list = tempUsers;
    //       }
    //       let users =
    //       this.partneruser &&
    //       this.partneruser.rows.map((data) => {
    //         return {
    //           id: data.id,
    //           name: data.name.charAt(0).toUpperCase() + data.name.slice(1),
    //           partner : data.registeredPartner.partner_name.charAt(0).toUpperCase() + data.registeredPartner.partner_name.slice(1),
    //           type : data.registeredPartner.type,
    //           email: data.email
    //         };
    //       });
         
    //       let tPlayer = users || [];
    //       console.log("PLEASE WORK",this.agent_players_list)
    //       let result = [...tPlayer,...this.agent_players_list]
        

    //     this.players_list = result;
    //   }
    // },
    async clearSeach(){
      this.selectPartnerUser = ""
      await this.searchData()
      await this.getAllPlayers()
    }
  },
  async mounted() {
    await this.searchData();
    await this.getPartnerProfileData({ id: this.$auth.user.id });
    await this.getUserInfo(this.$auth.user.id);
		await this.getPartnerSubPartners({id : this.$cookies.get("partner_id")});
    await this.getAllPlayers()
  },
  watch: {
    async viewDialog(){
        await this.searchData();
        await this.getPartnerProfileData({ id: this.$auth.user.id });
        await this.getUserInfo(this.$auth.user.id);
        await this.getPartnerSubPartners({id : this.$cookies.get("partner_id")});

    },
    async selectPlayer(value){
      let selected_player = await this.getPartnerUserInfo(value);
      this.userBalance = selected_player && selected_player.credits
      console.log("value",value)
    },
    async search(){
      await this.searchData();
    }
  },
};
</script>
<style>
.text-field-amount .v-input__control .v-input__slot{
	background: #222f3a;
}
.deposit {
  background: #0d111b !important;
}
.transfer-deposit-input {
  background: #222f3a !important;
}
.transfer-deposit {
  background: #222f3a !important;
  border-radius: 10px !important;
}
.transfer-deposit-text {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */

  text-align: center !important;

  color: #ffffff !important;
}
</style>
<style scoped>
.card {
	border-radius: 25px !important;
	background-color: #0d111b !important;
}
.dialog-btn {
	width: 100px;
	height: 36px;
}
</style>