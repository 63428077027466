import Vue from "vue";
import jwtDecode from "jwt-decode";
export default async (to, from, next) => {
  const hasToken = Vue.$cookies.isKey("_token");
  if (hasToken) {
    const token = Vue.$cookies.get("_token");
    // const decodedToken = jwtDecode(token);
    const decodedToken = jwtDecode(token);
    console.log("this.$auth",decodedToken)

    const is_partner = Vue.$cookies.get("is_partner");
    const is_subuser = Vue.$cookies.get("is_subuser");
    if (!is_partner && to.meta.partner && !is_subuser) {
      return next("/");
    }else{
      return next();
    }
  }
  if (to.meta.partner) {
    return next("/");
  }
  next();
};
