<template>
    <v-dialog v-model="viewDialog" width="700" @keydown.esc="closeDialog" @click:outside="closeDialog">
    <v-card class="transfer-money">
      <div class="tw-text-right tw-pr-4 tw-pt-3">
          <v-icon @click="closeDialog" color="red">mdi-close</v-icon>
        </div>
      <v-card-title class="text-h6 d-flex justify-center">
        Registration Link
      </v-card-title>
      <v-card-actions class="d-flex justify-space-around pt-10 pb-0">
         <v-text-field
            v-model="clipboardValue"
            readonly
            label="Registration Link"
            solo
            id="textField"
          ></v-text-field>
         
      </v-card-actions>
      <div class="pt-0 pb-5">
          <v-btn color="warning" rounded @click="copyTextFieldValue" class="px-5">Copy Link</v-btn>
      </div>
    </v-card>
    </v-dialog>
</template>
<script>
export default {
  props: ['registrationDialog','clipboardValue'],
  data(){
    return {
      registrationLink: "",
    }
  },
  computed: {
      viewDialog() {
      return this.registrationDialog;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog", true);
    },
    copyTextFieldValue(){
      var tempInput = document.getElementById('textField')
      tempInput.select();
      document.execCommand("copy");

      //Toast sucess
      this.$toast.success("Registration Link copied");
      this.$emit("closeDialog", true);
    }
  },
}
</script>