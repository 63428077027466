<template>
  <div>
    <!-- WEB VIEW -->
    <v-app-bar
      color="transparent"
      elevation="0"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <div class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10">
        <v-icon
          class="mr-5"
          v-if="!!this.partner && this.partner.status == 'approve'"
          @click="drawer = !drawer"
          >mdi-menu</v-icon
        >
        <v-icon
          class="mr-5"
          v-if="this.$auth.user && !!this.$auth.user.voucher_code"
          @click="drawer = !drawer"
          >mdi-menu</v-icon
        >
        <v-icon class="mr-5" v-if="!!is_subuser" @click="drawer = !drawer"
          >mdi-menu</v-icon
        >

        <div>
          <a @click="home"
            ><v-img
              width="140"
              :src="require('@/assets/images/betgame-gg.svg')"
            ></v-img
          ></a>
        </div>
      </div>
      <a
        v-if="
          !!this.$auth.user
            ? this.$auth.user.type == 'admin' ||
              this.$auth.user.type == 'super-admin'
            : this.$auth.user
        "
        @click="adminPage"
        style="z-index: 1"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
        >
          <v-icon class="tw-mr-2">mdi-shield-account</v-icon>Admin Dashboard
        </div></a
      >
      <!-- <a href="#" v-if="$route.name != 'Partner'" style="z-index: 1;"><div class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"><v-icon class="tw-mr-2">mdi-controller-classic</v-icon>Esports</div></a>
      <a href="#" v-if="$route.name != 'Partner'" style="z-index: 1;"><div class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"><v-icon class="tw-mr-2">mdi-newspaper</v-icon>Esport News</div></a>
      <a href="#" v-if="$route.name != 'Partner'" style="z-index: 1;"><div class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"><v-icon class="tw-mr-2">mdi-help-circle-outline</v-icon>Help</div></a> -->
      <a
        @click="profile"
        v-if="
          $route.name != 'Partner' &&
          !!this.$auth.user &&
          $route.name != 'profile' &&
          !this.partner
        "
        style="z-index: 1"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
        >
          <v-icon class="tw-mr-2">mdi-account</v-icon>Profile
        </div></a
      >
      <!-- <a
          @click="openFbChat"
          v-if="this.$auth.user && !this.partner && !this.$auth.user.voucher_code && !is_subuser && this.$auth.user.type != 'super-admin' && this.$auth.user.type != 'admin'"
          style="z-index: 1"
          ><div
            class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
          >
            <v-icon class="tw-mr-2">mdi-chat</v-icon>Chat with Us
          </div></a
        > -->

      <router-link
        to="/partner/profile"
        v-if="
          (!!this.partner && $route.name == 'PartnerDashboard') ||
          $route.name == 'PartnerProfile' ||
          $route.name == 'PartnerActivePlayers' ||
          $route.name == 'PartnerActiveAgents' ||
          $route.name == 'AgentTree'
        "
        style="z-index: 1"
      >
        <div>
          <div
            :class="` tw-flex tw-items-center tw-mr-10 ${
              $route.name == 'PartnerProfile'
                ? 'tw-font-bold tw-text-[#44d0d3]'
                : 'tw-font-light tw-text-white'
            }`"
          >
            <v-icon
              class="tw-mr-2"
              :color="$route.name == 'PartnerProfile' ? '#44d0d3' : ''"
              >mdi-account</v-icon
            >Partner Profile
          </div>
        </div>
      </router-link>
      <router-link
        to="/partner/agent-tree"
        v-if="
          (!!this.partner && $route.name == 'PartnerDashboard') ||
          $route.name == 'PartnerProfile' ||
          $route.name == 'PartnerActivePlayers' ||
          $route.name == 'PartnerActiveAgents' ||
          $route.name == 'AgentTree'
        "
        style="z-index: 1"
      >
        <div v-if="$route.name == 'AgentTree'">
          <div
            class="tw-font-bold tw-flex tw-items-center tw-mr-10"
            style="color: #44d0d3"
          >
            <v-icon class="tw-mr-2" color="#44d0d3">mdi-file-tree</v-icon>Agent
            Tree
          </div>
        </div>
        <div v-else>
          <div
            class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
          >
            <v-icon class="tw-mr-2">mdi-file-tree</v-icon>Agent Tree
          </div>
        </div>
      </router-link>
      <!-- Affiliate -->

      <router-link
        v-if="!this.partner && $route.name == 'AffiliateDashboard'"
        style="z-index: 1"
        to="/affiliate/active-players/"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
        >
          <v-icon class="tw-mr-2">mdi-account</v-icon>Active Players
        </div></router-link
      >
      <router-link
        v-if="
          (!!this.partner && $route.name == 'PartnerDashboard') ||
          $route.name == 'PartnerProfile' ||
          $route.name == 'AgentTree' ||
          $route.name == 'PartnerActiveAgents' ||
          $route.name == 'PartnerActivePlayers'
        "
        style="z-index: 1"
        to="/partner/active-players/"
      >
        <div v-if="$route.name == 'PartnerActivePlayers'">
          <div
            class="tw-font-bold tw-flex tw-items-center tw-mr-10"
            style="color: #44d0d3"
          >
            <v-icon class="tw-mr-2" color="#44d0d3">mdi-account-check</v-icon
            >Active Players
          </div>
        </div>
        <div v-else>
          <div
            class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
          >
            <v-icon class="tw-mr-2">mdi-account-check</v-icon>Active Players
          </div>
        </div>
      </router-link>
      <!-- <router-link
        to="/partner/active-agents/"
        v-if="!!this.partner && $route.name == 'PartnerDashboard' || $route.name == 'PartnerProfile' || $route.name == 'PartnerActivePlayers' || $route.name == 'AgentTree' || $route.name == 'PartnerActiveAgents'"
        style="z-index: 1"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
        >
          <v-icon class="tw-mr-2">mdi-account</v-icon>Active Agents
        </div></router-link
      > -->
      <!-- SUB USER ACTIVE PLAYERs AND AGENT -->
      <router-link
        v-if="
          (is_subuser && $route.name == 'SubUserTransactionHistory') ||
          $route.name == 'SubUserActiveAgent' ||
          $route.name == 'SubUserActivePlayers' ||
          $route.name == 'SubUserDashboard'
        "
        style="z-index: 1"
        to="/partner/sub-user/active-players/"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
        >
          <v-icon class="tw-mr-2">mdi-account</v-icon>Active Players
        </div></router-link
      >
      <!-- <router-link
        to="/partner/sub-user/active-agents/"
        v-if="is_subuser && $route.name == 'SubUserTransactionHistory' || $route.name == 'SubUserActiveAgent' || $route.name == 'SubUserActivePlayers' || $route.name == 'SubUserDashboard'"
        style="z-index: 1"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
        >
          <v-icon class="tw-mr-2">mdi-account</v-icon>Active Agents
        </div></router-link
      > -->
      <!-- END SUB USER ACTIVE PLAYERs AND AGENT -->
      <a
        v-if="
          ($route.name != 'Partner' &&
            !!this.$auth.user &&
            $route.name == 'profile') ||
          $route.name == 'CasinoHome' ||
          $route.name == 'CasinoGames' ||
          $route.name == 'CasinoLive' ||
          $route.name == 'CasinoSlots' ||
          $route.name == 'CasinoPlay' ||
          $route.name == 'CasinoPlayTest' ||
          $route.name == 'CasinoAdmin'
        "
        style="z-index: 1; width: 60px"
        href="/?bt-path=%2F"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
        >
          <v-icon class="tw-mr-3"></v-icon>Home
        </div></a
      >
      <a
        v-if="
          ($route.name != 'Partner' &&
            !!this.$auth.user &&
            $route.name == 'profile') ||
          $route.name == 'CasinoHome' ||
          $route.name == 'CasinoGames' ||
          $route.name == 'CasinoLive' ||
          $route.name == 'CasinoSlots' ||
          $route.name == 'CasinoPlay' ||
          $route.name == 'CasinoPlayTest' ||
          $route.name == 'CasinoAdmin'
        "
        style="z-index: 1; width: 60px"
        href="/casino"
        ><div
          :class="`${$route.name == 'CasinoHome' || $route.name == 'CasinoGames' || $route.name == 'CasinoLive' || $route.name == 'CasinoSlots' || $route.name == 'CasinoPlay' || $route.name == 'CasinoPlayTest' || $route.name == 'CasinoAdmin'? 'tw-font-bold tw-text-[#44d0d3]' : 'tw-text-white tw-font-light'} tw-flex tw-items-center tw-mr-10 `" 
        >
          <v-icon class="tw-mr-3"></v-icon>Casino
        </div></a
      >
      <a
        v-if="
          ($route.name != 'Partner' &&
            !!this.$auth.user &&
            $route.name == 'profile') ||
          $route.name == 'CasinoHome' ||
          $route.name == 'CasinoGames' ||
          $route.name == 'CasinoLive' ||
          $route.name == 'CasinoSlots' ||
          $route.name == 'CasinoPlay' ||
          $route.name == 'CasinoPlayTest' ||
          $route.name == 'CasinoAdmin'
        "
        style="z-index: 1; width: 47px"
        href="/?bt-path=%2Flive"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
        >
          <v-icon class="tw-mr-3"></v-icon>Live
        </div></a
      >
      <a
        v-if="
          ($route.name != 'Partner' &&
            !!this.$auth.user &&
            $route.name == 'profile') ||
          $route.name == 'CasinoHome' ||
          $route.name == 'CasinoGames' ||
          $route.name == 'CasinoLive' ||
          $route.name == 'CasinoSlots' ||
          $route.name == 'CasinoPlay' ||
          $route.name == 'CasinoPlayTest' ||
          $route.name == 'CasinoAdmin'
        "
        style="z-index: 1; width: 69px"
        href="/?bt-path=%2Fe_sport"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
        >
          <v-icon class="tw-mr-3"></v-icon>Esports
        </div></a
      >
      <v-menu
        bottom
        left
        v-if="
          ($route.name != 'Partner' &&
            !!this.$auth.user &&
            $route.name == 'profile') ||
          $route.name == 'CasinoHome' ||
          $route.name == 'CasinoGames' ||
          $route.name == 'CasinoLive' ||
          $route.name == 'CasinoSlots' ||
          $route.name == 'CasinoPlay' ||
          $route.name == 'CasinoPlayTest' ||
          $route.name == 'CasinoAdmin'
        "
        style="width: 60px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text class="align-self-center" v-bind="attrs" v-on="on">
            Sports
            <v-icon right> mdi-menu-down </v-icon>
          </v-btn>
        </template>
        <v-list dark class="">
          <v-list-item
            v-for="item in sports"
            @click="goTo(item.link)"
            :key="item.link"
          >
            {{ item.name }}
          </v-list-item>
        </v-list>
      </v-menu>
      <a
        v-if="
          ($route.name != 'Partner' &&
            !!this.$auth.user &&
            $route.name == 'profile') ||
          $route.name == 'CasinoHome' ||
          $route.name == 'CasinoGames' ||
          $route.name == 'CasinoLive' ||
          $route.name == 'CasinoSlots' ||
          $route.name == 'CasinoPlay' ||
          $route.name == 'CasinoPlayTest' ||
          $route.name == 'CasinoAdmin'
        "
        style="z-index: 1; width: 81px"
        href="/?bt-path=%2Ffavorites"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
        >
          <v-icon class="tw-mr-3"></v-icon>Favorites
        </div></a
      >
      <a
        v-if="
          ($route.name != 'Partner' &&
            !!this.$auth.user &&
            $route.name == 'profile') ||
          $route.name == 'CasinoHome' ||
          $route.name == 'CasinoGames' ||
          $route.name == 'CasinoLive' ||
          $route.name == 'CasinoSlots' ||
          $route.name == 'CasinoPlay' ||
          $route.name == 'CasinoPlayTest' ||
          $route.name == 'CasinoAdmin'
        "
        style="z-index: 1; width: 135px"
        href="/?bt-path=%2Fbets"
        ><div class="tw-text-white tw-font-light tw-flex tw-items-center">
          <v-icon class="tw-mr-3"></v-icon>
          Sportsbook Bets
        </div></a
      >
      <a
        v-if="
          ($route.name != 'Partner' &&
            !!this.$auth.user &&
            $route.name == 'profile') ||
          $route.name == 'CasinoHome' ||
          $route.name == 'CasinoGames' ||
          $route.name == 'CasinoLive' ||
          $route.name == 'CasinoSlots' ||
          $route.name == 'CasinoPlay' ||
          $route.name == 'CasinoPlayTest' ||
          $route.name == 'CasinoAdmin'
        "
        style="z-index: 1; width: 10px"
        href="/?bt-path=%2Fsearch"
        ><div class="tw-text-white tw-font-light tw-flex tw-items-center">
          <v-icon class="tw-mr-3"></v-icon>Search
        </div></a
      >

      <!-- <a
        @click="FrequentlyAskedQuestions"
        v-if="!this.$auth.user"
        style="z-index: 1"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
        >
          <v-icon class="tw-mr-2">mdi-frequently-asked-questions</v-icon>Frequently Asked Questions (FAQs)
        </div></a
      >
      <a
        @click="ResponsibleGamblingPolicy"
        v-if="!this.$auth.user"
        style="z-index: 1"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
        >
          <v-icon class="tw-mr-2">mdi-text-box-check-outline</v-icon>Responsible Gambling Policy
        </div></a
      > -->
      <!-- <a
        @click="openDirectDialog"
        v-if="$route.name != 'Partner' && !!this.$auth.user"
        style="z-index: 1"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
        >
          Dialog
        </div></a
      >
      <a
        @click="openPartnerDialog"
        v-if="$route.name != 'Partner' && !!this.$auth.user"
        style="z-index: 1"
        ><div
          class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
        >
          Partner Dialog
        </div></a
      > -->
      <v-spacer></v-spacer>
      <div v-if="!$auth.user" class="tw-flex">
        <!-- <v-btn
          small
          @click="openFbChat"
          text
          elevation="0"
          class="px-6"
          style="z-index: 1"
          ><v-icon class="pr-1">mdi-chat-processing-outline</v-icon>Chat with Us</v-btn
        > -->
        <v-btn
          @click="showLogin"
          small
          color="#4589D2"
          class="px-6"
          style="z-index: 1"
          >login</v-btn
        >
        <v-btn
          @click="showRegister"
          small
          color="#D28945"
          class="px-6 ml-2"
          style="z-index: 1"
          >sign up</v-btn
        >
      </div>
      <div v-else class="d-flex align-center">
        <v-row
          v-if="
            (!!this.partner && $route.name == 'PartnerDashboard') ||
            $route.name == 'PartnerProfile' ||
            $route.name == 'AgentTree' ||
            $route.name == 'PartnerActivePlayers' ||
            $route.name == 'PartnerActiveAgents' ||
            $route.name == 'SubUserTransactionHistory' ||
            $route.name == 'SubUserActiveAgent' ||
            $route.name == 'SubUserActivePlayers' ||
            $route.name == 'SubUserDashboard'
          "
          dense
          :class="$route.name != 'home' ? 'px-10 pb-0' : 'px-10 pb-2'"
        >
          <!-- PARTNER HEADER CREDITS -->
          <v-col v-if="this.partner" cols="12">
            <v-row class="amounts">
              <v-icon class="ml-10">mdi-wallet</v-icon>
              <v-col class="pl-3 text-caption text-left">
                <div>Partner Credits</div>
                <div>
                  ₱
                  {{
                    partnerCredits
                      ? Number(partnerCredits).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : ""
                  }}
                </div>
                <!-- <div>₱ {{ this.partner.partner_credits | formatMoney }}</div> -->
              </v-col>
            </v-row>
          </v-col>
          <!-- END PARTNER HEADER CREDITS -->
          <!-- SUB USER HEADER CREDITS -->
          <v-col v-if="is_subuser" cols="12">
            <v-row class="amounts">
              <v-icon class="ml-10">mdi-wallet</v-icon>
              <v-col class="pl-3 text-caption text-left">
                <div>Partner Credits</div>
                <div>
                  ₱
                  {{
                    subUserPartnerCredits
                      ? Number(subUserPartnerCredits).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          }
                        )
                      : ""
                  }}
                </div>
                <!-- <div>₱ {{ this.partner.partner_credits | formatMoney }}</div> -->
              </v-col>
            </v-row>
          </v-col>
          <!-- END SUB USER HEADER CREDITS -->
        </v-row>
        <v-row class="mr-1">
          <v-col
            class="pr-0 text-right"
            v-if="
              !!this.$auth.user
                ? this.$auth.user.type == 'super-admin'
                : this.$auth.user
            "
          >
            <!-- <div
              class="pa-0"
              v-if="!!this.$auth.user ? this.$auth.user.type == 'super-admin' : this.$auth.user">
              <v-btn icon @click="openMessage" v-clipboard="clipboardValue"
                ><v-icon class="">mdi-message</v-icon></v-btn>
            </div> -->
            <div class="pa-0">
              <v-btn icon stacked @click="openMessage">
                <div v-if="this.getUserMessageData == 0">
                  <v-icon>mdi-message</v-icon>
                </div>
                <div v-else>
                  <v-badge :content="this.getUserMessageData" color="error">
                    <v-icon>mdi-message</v-icon>
                  </v-badge>
                </div>
              </v-btn>
            </div>
          </v-col>
          <v-col class="pr-1">
            <div
              v-if="
                user_info.credits &&
                $route.name != 'PartnerDashboard' &&
                $route.name != 'PartnerProfile' &&
                $route.name != 'AgentTree' &&
                $route.name != 'PartnerActivePlayers' &&
                $route.name != 'PartnerActiveAgents' &&
                $route.name != 'SubUserTransactionHistory' &&
                $route.name != 'SubUserActiveAgent' &&
                $route.name != 'SubUserActivePlayers' &&
                $route.name != 'SubUserDashboard'
              "
              class="font-weight-bold"
              style="font-size: 11px !important"
            >
              WALLET:
              <v-chip
                label
                small
                outlined
                color="rgb(15 239 131)"
                text-color="white"
                v-if="$route.name == 'CasinoPlay'"
              >
                <h6>Playing..</h6>
              </v-chip>
              <v-chip
                label
                small
                outlined
                color="rgb(15 239 131)"
                text-color="white"
                v-if="$route.name != 'CasinoPlay'"
                >₱
                {{
                  userCredits
                    ? Number(userCredits).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : ""
                }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
        <v-divider
          v-if="
            (!!this.partner && $route.name == 'PartnerDashboard') ||
            $route.name == 'PartnerProfile' ||
            $route.name == 'AgentTree' ||
            $route.name == 'PartnerActivePlayers' ||
            $route.name == 'PartnerActiveAgents' ||
            $route.name == 'SubUserTransactionHistory' ||
            $route.name == 'SubUserActiveAgent' ||
            $route.name == 'SubUserActivePlayers' ||
            $route.name == 'SubUserDashboard'
          "
          vertical
          class="divider"
        ></v-divider>
        <!-- PARTNER HEADER -->
        <v-col
          v-if="
            $route.name == 'PartnerDashboard' ||
            $route.name == 'PartnerProfile' ||
            $route.name == 'AgentTree' ||
            $route.name == 'PartnerActivePlayers' ||
            $route.name == 'PartnerActiveAgents'
          "
          class="pa-0"
        >
          <v-tooltip bottom color="#37a1a8">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="openUserDialog"
                ><div class="">
                  <v-icon class="">mdi-account-plus</v-icon>
                </div></v-btn
              >
            </template>
            <span>Create User</span>
          </v-tooltip>
        </v-col>
        <v-col
          v-if="
            $route.name == 'PartnerDashboard' ||
            $route.name == 'PartnerProfile' ||
            $route.name == 'AgentTree' ||
            $route.name == 'PartnerActivePlayers' ||
            $route.name == 'PartnerActiveAgents'
          "
          class="pa-0"
        >
          <v-tooltip bottom color="#37a1a8">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="openTransferMoneyDialog"
                ><div class="">
                  <v-icon class="">mdi-swap-horizontal</v-icon>
                </div></v-btn
              >
            </template>
            <span>Transfer Money</span>
          </v-tooltip>
        </v-col>
        <v-col
          class="pa-0"
          v-if="
            (!!this.partner && $route.name == 'PartnerDashboard') ||
            $route.name == 'PartnerProfile' ||
            $route.name == 'AgentTree' ||
            $route.name == 'PartnerActivePlayers' ||
            $route.name == 'PartnerActiveAgents'
          "
        >
          <v-tooltip bottom color="#37a1a8">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="openRegistrationLinkDialog"
                v-clipboard="clipboardValue"
                ><v-icon class="">mdi-link-plus</v-icon></v-btn
              >
            </template>
            <span>Registration Link</span>
          </v-tooltip>
        </v-col>
        <v-col
          class="pa-0"
          v-if="
            (!!this.partner && $route.name == 'PartnerDashboard') ||
            $route.name == 'PartnerProfile' ||
            $route.name == 'AgentTree' ||
            $route.name == 'PartnerActivePlayers' ||
            $route.name == 'PartnerActiveAgents' ||
            $route.name == 'AffiliateDashboard'
          "
        >
          <div class="pa-0">
            <v-tooltip bottom color="#37a1a8">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  stacked
                  @click="openMessage"
                >
                  <div v-if="getUserMessageData == 0">
                    <v-icon>mdi-message</v-icon>
                  </div>
                  <div v-else>
                    <v-badge :content="getUserMessageData" color="error">
                      <v-icon>mdi-message</v-icon>
                    </v-badge>
                  </div>
                </v-btn>
              </template>
              <span>Messages</span>
            </v-tooltip>
          </div>
        </v-col>
        <div
          v-if="
            (this.partner && $route.name == 'PartnerDashboard') ||
            $route.name == 'PartnerProfile' ||
            $route.name == 'AgentTree' ||
            $route.name == 'PartnerActivePlayers' ||
            $route.name == 'PartnerActiveAgents'
          "
          :class="$route.name != 'home' ? 'tw-my-2 pb-0' : 'tw-my-2 pb-2'"
        >
          <v-menu
            offset-y
            left
            elevation="0"
            content-class="elevation-0"
            style="z-index: 9999 !important"
          >
            <template v-slot:activator="{ on, attrs }">
              <a v-bind="attrs" v-on="on" class="d-flex align-center"
                ><v-btn color="#45D0D2BF" rounded class="ma-2 white--text"
                  ><v-icon dark left>mdi-account-circle</v-icon
                  >{{ partner.partner_name
                  }}<v-icon right dark>mdi-chevron-down</v-icon></v-btn
                ></a
              >
            </template>
            <div
              class="tw-mt-2 tw-py-3 tw-rounded-md tw-bg-white tw-border tw-border-gray-300 tw-shadow-md dropdown"
              style="background: #141c2e !important"
            >
              <v-list
                light
                class="py-0 my-0"
                style="background: transparent"
                width="200"
              >
                <v-list-item-group>
                  <v-list-item @click="profile">
                    <v-list-item-content>
                      <div class="tw-text-white px-5 text-left">Profile</div>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item @click="message">
                    <v-list-item-content>
                      <div class="tw-text-white pl-3 text-left">Messages</div>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-list-item @click="logoutUser">
                    <v-list-item-content>
                      <div class="tw-text-white px-5 text-left">Logout</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-menu>
        </div>
        <div
          v-else-if="
            (!partner &&
              !is_subuser &&
              $route.name == 'SubUserTransactionHistory') ||
            $route.name == 'SubUserActiveAgent' ||
            $route.name == 'SubUserActivePlayers' ||
            $route.name == 'SubUserDashboard'
          "
          class="tw-my-2"
        ></div>
        <div v-else class="tw-my-2">
          <v-menu
            offset-y
            left
            elevation="0"
            content-class="elevation-0"
            style="z-index: 9999 !important"
          >
            <template v-slot:activator="{ on, attrs }">
              <a v-bind="attrs" v-on="on" class="d-flex align-center"
                ><v-avatar
                  ><v-img
                    :src="
                      $auth.user.avatar == null
                        ? require('../assets/profile.svg')
                        : $auth.user.avatar
                    "
                    class="d-flex align-end rounded-circle"
                  ></v-img></v-avatar
              ></a>
            </template>
            <div
              class="tw-mt-2 tw-py-3 tw-rounded-md tw-bg-white tw-border tw-border-gray-300 tw-shadow-md dropdown"
              style="background: #141c2e !important"
            >
              <v-list
                light
                class="py-0 my-0"
                style="background: transparent"
                width="200"
              >
                <v-list-item-group>
                  <v-list-item @click="profile">
                    <v-list-item-content>
                      <div
                        class="tw-text-white px-5 text-body-1 font-weight-bold text-uppercase"
                      >
                        {{ user_info.name ? user_info.name : "" }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider dark></v-divider>
                  <v-list-item @click="profile">
                    <v-list-item-content>
                      <div class="tw-text-white pl-3 text-left">Profile</div>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item @click="message">
                    <v-list-item-content>
                      <div class="tw-text-white pl-3 text-left">Messages</div>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-list-item @click="logoutUser">
                    <v-list-item-content>
                      <div class="tw-text-white pl-3 text-left">Logout</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-menu>
        </div>
        <!-- END PARTNER HEADER -->
        <!-- SUB USER HEADER -->
        <v-col
          v-if="
            (!!this.is_subuser && $route.name == 'SubUserTransactionHistory') ||
            $route.name == 'SubUserActiveAgent' ||
            $route.name == 'SubUserActivePlayers' ||
            $route.name == 'SubUserDashboard'
          "
          class="pa-0"
        >
          <v-btn icon @click="openUserDialog"
            ><div class="">
              <v-icon class="">mdi-account-plus</v-icon>
            </div></v-btn
          >
        </v-col>
        <v-col
          v-if="
            (!!this.is_subuser && $route.name == 'SubUserTransactionHistory') ||
            $route.name == 'SubUserActiveAgent' ||
            $route.name == 'SubUserActivePlayers' ||
            $route.name == 'SubUserDashboard'
          "
          class="pa-0"
        >
          <v-btn icon @click="openSubUserTransferMoneyDialog"
            ><div class="">
              <v-icon class="">mdi-swap-horizontal</v-icon>
            </div></v-btn
          >
        </v-col>
        <v-col
          class="pa-0"
          v-if="
            (!!this.is_subuser && $route.name == 'SubUserTransactionHistory') ||
            $route.name == 'SubUserActiveAgent' ||
            $route.name == 'SubUserActivePlayers' ||
            $route.name == 'SubUserDashboard'
          "
        >
          <v-btn
            icon
            @click="openRegistrationLinkDialog"
            v-clipboard="clipboardValue"
            ><v-icon class="">mdi-link-plus</v-icon></v-btn
          >
        </v-col>
        <!-- <v-col
          class="pa-0"
          v-if="!!this.is_subuser && $route.name == 'SubUserTransactionHistory' || $route.name == 'SubUserActiveAgent' || $route.name == 'SubUserActivePlayers' || $route.name == 'SubUserDashboard' || $route.name == 'AffiliateDashboard'"
        >
          <div class="pa-0">
            <v-btn icon stacked @click="openMessage">
            <div v-if="this.getUserMessageData == 0">
                <v-icon>mdi-message</v-icon>
              </div>
              <div v-else>
              <v-badge :content=" this.getUserMessageData" color="error">
                  <v-icon>mdi-message</v-icon> 
                </v-badge>
              </div>
            </v-btn>
          </div>
        </v-col> -->
        <div
          v-if="
            (!!this.is_subuser && $route.name == 'SubUserTransactionHistory') ||
            $route.name == 'SubUserActiveAgent' ||
            $route.name == 'SubUserActivePlayers' ||
            $route.name == 'SubUserDashboard'
          "
          :class="$route.name != 'home' ? 'tw-my-2 pb-0' : 'tw-my-2 pb-2'"
        >
          <v-menu
            offset-y
            left
            elevation="0"
            content-class="elevation-0"
            style="z-index: 9999 !important"
          >
            <template v-slot:activator="{ on, attrs }">
              <a v-bind="attrs" v-on="on" class="d-flex align-center"
                ><v-btn color="#45D0D2BF" rounded class="ma-2 white--text"
                  ><v-icon dark left>mdi-account-circle</v-icon
                  >{{ user_info.name ? user_info.name : ""
                  }}<v-icon right dark>mdi-chevron-down</v-icon></v-btn
                ></a
              >
            </template>
            <div
              class="tw-mt-2 tw-py-3 tw-rounded-md tw-bg-white tw-border tw-border-gray-300 tw-shadow-md dropdown"
              style="background: #141c2e !important"
            >
              <v-list
                light
                class="py-0 my-0"
                style="background: transparent"
                width="200"
              >
                <v-list-item-group>
                  <v-list-item @click="profile">
                    <v-list-item-content>
                      <div class="tw-text-white px-5 text-left">Profile</div>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item @click="message">
                    <v-list-item-content>
                      <div class="tw-text-white pl-3 text-left">Messages</div>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-list-item @click="logoutUser">
                    <v-list-item-content>
                      <div class="tw-text-white px-5 text-left">Logout</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-menu>
        </div>
        <!-- END SUB USER HEADER -->
      </div>
    </v-app-bar>
    <!-- MOBILE VIEW -->
    <div
      v-else
      class="tw-border-gray-300 tw-flex tw-justify-between tw-items-center tw-p-3"
    >
      <div class="d-flex align-center">
        <!-- <v-icon class="mr-1" @click="drawer = !drawer" v-if="!!this.$auth.user"
          >mdi-menu</v-icon
        > -->
        <v-icon
          class="mr-5"
          v-if="!!this.partner && this.partner.status == 'approve'"
          @click="drawer = !drawer"
          >mdi-menu</v-icon
        >
        <v-icon
          class="mr-5"
          v-if="this.$auth.user && !!this.$auth.user.voucher_code"
          @click="drawer = !drawer"
          >mdi-menu</v-icon
        >
        <v-icon class="mr-5" v-if="!!is_subuser" @click="drawer = !drawer"
          >mdi-menu</v-icon
        >
        <v-icon
          class="mr-5"
          v-if="
            this.$auth.user &&
            !this.partner &&
            !this.$auth.user.voucher_code &&
            !is_subuser
          "
          @click="bettordrawer = !bettordrawer"
          >mdi-menu</v-icon
        >
        <div>
          <a @click="home"
            ><v-img
              width="140"
              :src="require('@/assets/images/betgame-gg.svg')"
            ></v-img
          ></a>
        </div>
      </div>
      <div v-if="!$auth.user" class="tw-flex">
        <v-btn @click="showLogin" x-small color="#4589D2">login</v-btn>
        <v-btn @click="showRegister" x-small color="#D28945" class="ml-2"
          >sign up</v-btn
        >
      </div>
      <div v-else>
        <!-- Mobile Version Partner & Agent -->
        <div
          class="d-flex align-center"
          v-if="
            (!!this.partner && $route.name == 'PartnerDashboard') ||
            $route.name == 'PartnerProfile' ||
            $route.name == 'AgentTree' ||
            $route.name == 'PartnerActivePlayers' ||
            $route.name == 'PartnerActiveAgents'
          "
        >
          <div>
            <v-row>
              <v-col cols="12">
                <v-row class="amounts">
                  <v-icon class="ml-5">mdi-wallet</v-icon>
                  <v-col class="pl-2 text-caption text-left">
                    <div>Partner Credits</div>
                    <div>
                      ₱ {{ this.partner.partner_credits | formatMoney }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <div>
            <div class="pl-2">
              <v-menu
                offset-y
                left
                elevation="0"
                content-class="elevation-0"
                style="z-index: 9999 !important"
              >
                <template v-slot:activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on" class="d-flex align-center"
                    ><v-btn icon
                      ><v-avatar color="white" size="25"
                        ><v-icon color="black">mdi-account</v-icon></v-avatar
                      ></v-btn
                    ></a
                  >
                </template>
                <div
                  class="tw-mt-1 tw-py-1 tw-rounded-md tw-bg-white tw-border tw-border-gray-300 tw-shadow-md dropdown"
                  style="background: #141c2e !important"
                >
                  <v-list
                    light
                    class="py-0 my-0"
                    style="background: transparent"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <div class="tw-text-white px-5">
                          {{ user_info.name }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider style="background: white !important"></v-divider>
                    <v-list-item-group>
                      <v-list-item @click="profile">
                        <v-list-item-content>
                          <div class="tw-text-white px-5">Profile</div>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="logoutUser">
                        <v-list-item-content>
                          <div class="tw-text-white px-5">Logout</div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-menu>
            </div>
          </div>
        </div>
        <div v-else class="d-flex align-center">
          <div
            class="font-weight-bold tw-invisible"
            style="font-size: 8px !important"
          >
            WALLET:
            <v-chip
              label
              small
              outlined
              color="rgb(15 239 131)"
              text-color="white"
              v-if="$route.name == 'CasinoPlay'"
            >
              <h6>Playing..</h6>
            </v-chip>
            <v-chip
              label
              small
              outlined
              color="rgb(15 239 131)"
              text-color="white"
              v-if="$route.name != 'CasinoPlay'"
              >₱
              {{
                userCredits
                  ? Number(userCredits).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  : ""
              }}
            </v-chip>
          </div>
          <div class="tw-my-2">
            <v-menu
              offset-y
              left
              elevation="0"
              content-class="elevation-0"
              style="z-index: 9999 !important"
            >
              <template v-slot:activator="{ on, attrs }">
                <a v-bind="attrs" v-on="on" class="d-flex align-center"
                  ><v-btn icon
                    ><v-avatar color="white" size="25"
                      ><v-icon color="black">mdi-account</v-icon></v-avatar
                    ></v-btn
                  ></a
                >
              </template>
              <div
                class="tw-mt-1 tw-py-1 tw-rounded-md tw-bg-white tw-border tw-border-gray-300 tw-shadow-md dropdown"
                style="background: #141c2e !important"
              >
                <v-list light class="py-0 my-0" style="background: transparent">
                  <v-list-item>
                    <v-list-item-content>
                      <div class="tw-text-white px-5">{{ user_info.name }}</div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider style="background: white !important"></v-divider>
                  <v-list-item align="left">
                    <v-list-item-content>
                      <div class="tw-flex tw-items-center tw-px-5">
                        <v-icon color="white">mdi-wallet</v-icon>
                        <div
                          class="tw-text-white px-5 tw-text-sm tw-text-[#0fef83]"
                        >
                          {{
                            userCredits
                              ? Number(userCredits).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                })
                              : ""
                          }}
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider style="background: white !important"></v-divider>
                  <v-list-item-group align="left">
                    <v-list-item @click="profile">
                      <v-list-item-content>
                        <div class="tw-flex tw-items-center tw-px-5">
                          <v-icon color="white">mdi-account</v-icon>
                          <div class="tw-text-white px-5">Profile</div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="logoutUser">
                      <v-list-item-content>
                        <div class="tw-flex tw-items-center tw-px-5">
                          <v-icon color="white">mdi-logout</v-icon>
                          <div class="tw-text-white px-5">Logout</div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-menu>
          </div>
        </div>
        <v-navigation-drawer v-model="drawer" fixed temporary dark>
          <v-list>
            <v-list-item-group v-model="selectedItem">
              <v-list-item>
                <v-list-item-content
                  v-if="
                    !!this.$auth.user
                      ? this.$auth.user.type == 'admin'
                      : this.$auth.user
                  "
                  @click="adminPage"
                >
                  <v-list-item-title
                    @click="adminPage"
                    class="tw-flex tw-items-center"
                    ><v-icon class="mr-2">mdi-shield-account</v-icon>Admin
                    Dashboard</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="item in items" :key="item.text">
                <v-list-item-content>
                  <v-list-item-title class="tw-flex tw-items-center"
                    ><v-icon class="mr-2">{{ item.icon }}</v-icon
                    >{{ item.text }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>
    </div>
    <!-- Navigation Bettor -->
    <v-navigation-drawer
      color="#0D111B"
      overlay-opacity="0.8"
      overlay-color="#000000BF"
      v-model="bettordrawer"
      fixed
      temporary
      dark
      width="275"
      style="z-index: 9999"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <div
              class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
            >
              <v-icon class="mr-5" @click="bettordrawer = !bettordrawer"
                >mdi-menu</v-icon
              >
              <div>
                <a @click="home"
                  ><v-img
                    width="140"
                    :src="require('@/assets/images/betgame-gg.svg')"
                  ></v-img
                ></a>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-list-item-group v-model="selectedItem">
          <div class="text-center">
            <v-divider></v-divider>
            <v-list-item
              v-if="
                !!this.$auth.user
                  ? this.$auth.user.type == 'admin' ||
                    this.$auth.user.type == 'super-admin'
                  : this.$auth.user
              "
            >
              <v-list-item-content>
                <v-list-item-title
                  class="tw-flex tw-items-center"
                  @click="adminPage"
                  ><v-icon class="mr-2">mdi-shield-account</v-icon>Admin
                  Dashboard</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="
                !!this.$auth.user
                  ? this.$auth.user.type == 'super-admin'
                  : this.$auth.user
              "
            >
              <v-list-item-content>
                <v-list-item-title
                  class="tw-flex tw-items-center"
                  @click="openMessage"
                  ><v-icon class="mr-2">mdi-message</v-icon
                  >Messages</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <div
              v-if="
                !!this.$auth.user &&
                this.$auth.user.type != 'admin' &&
                this.$auth.user.type != 'super-admin' &&
                this.$auth.user
              "
            >
              <v-list-item
                v-for="(item, i) in user_items.filter(
                  (item) => item.text !== 'SPORTS'
                )"
                :key="i"
                :href="item.href"
              >
                <v-list-item-content>
                  <v-list-item-title class="tw-flex tw-items-center"
                    ><v-icon class="mr-4">{{ item.icon }}</v-icon
                    ><span class="tw-text-sm">{{
                      item.text
                    }}</span></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-group
                v-if="hasSubheader"
                :value="nestedListGroupOpen"
                class=""
              >
                <template v-slot:activator>
                  <v-list-item :class="$route.name == 'Home' ? '' : 'px-0'">
                    <v-list-item-content>
                      <v-list-item-title class="tw-flex tw-items-center"
                        ><v-icon class="mr-4">mdi-basketball</v-icon
                        ><span class="tw-text-sm"
                          >SPORTS</span
                        ></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>

                <!-- Nested list items -->
                <v-list-item
                  v-for="(subItem, subIndex) in user_items.find(
                    (item) => item.text === 'SPORTS'
                  ).subheader"
                  :key="subIndex"
                  :href="subItem.link"
                >
                  <v-list-item-content>
                    <v-list-item-title class="tw-flex tw-items-center tw-pl-5"
                      ><span class="tw-text-sm">{{
                        subItem.name
                      }}</span></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </div>
            <!-- <v-divider></v-divider>
             <div class="mt-5">
              <span class="pr-4"><v-icon>mdi-twitter</v-icon></span>
              <a href="https://www.facebook.com/betgamegg/"><span class="pr-4"><v-icon>mdi-facebook</v-icon></span></a>
              <span class="pr-4"><v-icon>mdi-instagram</v-icon></span>
              <span><v-icon>mdi-gmail</v-icon></span>
             </div> -->
            <v-divider></v-divider>
            <div class="tw-py-4 tw-text-sm tw-text-white">
              <v-icon class="mr-2">mdi-facebook</v-icon>
              <a href="https://www.facebook.com/betgamegg" class="facebook-link"
                >www.facebook.com/betgamegg</a
              >
            </div>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      color="#0D111B"
      overlay-opacity="0.8"
      overlay-color="#000000BF"
      v-model="drawer"
      fixed
      temporary
      dark
      style="z-index: 9999"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <div
              class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
            >
              <v-icon class="mr-5" @click="drawer = !drawer">mdi-menu</v-icon>
              <div>
                <a @click="home"
                  ><v-img
                    width="140"
                    :src="require('@/assets/images/betgame-gg.svg')"
                  ></v-img
                ></a>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list>
        <v-list-item-group v-model="selectedItem">
          <div >
            <v-divider></v-divider>
            <v-list-item >
              <v-list-item-content>
                <v-list-item-title class="tw-flex tw-items-center" @click="openFbChat"><v-icon class="mr-2">mdi-chat</v-icon>Chat with Us</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list> -->
    </v-navigation-drawer>
    <v-navigation-drawer
      color="#0D111B"
      overlay-opacity="0.8"
      overlay-color="#000000BF"
      v-model="drawer"
      fixed
      temporary
      dark
      style="z-index: 9999"
      v-if="
        !!this.partner ||
        $route.name == 'PartnerDashboard' ||
        $route.name == 'PartnerProfile' ||
        $route.name == 'AgentTree' ||
        $route.name == 'PartnerActivePlayers' ||
        $route.name == 'PartnerActiveAgents'
      "
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <div
              class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
            >
              <v-icon class="mr-5" @click="drawer = !drawer">mdi-menu</v-icon>
              <div>
                <a @click="home"
                  ><v-img
                    width="140"
                    :src="require('@/assets/images/betgame-gg.svg')"
                  ></v-img
                ></a>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-list-item-group v-model="selectedItem">
          <v-list-item class="title">
            <v-list-item-content>
              <v-list-item-title
                @click="adminPage"
                class="tw-flex tw-items-center"
                >PARTNER DASHBOARD</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <div>
            <v-row class="py-1">
              <v-col
                cols="2"
                class="text-left tw-pl-7 pb-0 pt-0"
                style="align-self: center"
              >
                <v-icon>mdi-wallet</v-icon>
              </v-col>
              <v-col cols="10" class="text-left tw-pl-4">
                <div>Partner Credits</div>
                ₱
                {{
                  partnerCredits
                    ? Number(partnerCredits).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : ""
                }}
              </v-col>
            </v-row>
          </div>
          <v-list-item
            v-for="item in partner_items"
            :key="item.text"
            :to="item.link"
          >
            <v-list-item-content>
              <v-list-item-title class="tw-flex tw-items-center"
                ><v-icon class="mr-2">{{ item.icon }}</v-icon
                >{{ item.text }}</v-list-item-title
              >
              <!-- <v-list-item-subtitle class="d-flex justify-left ml-8" v-if="item.show_credits"> ₱
                {{
                  partnerCredits
                    ? Number(partnerCredits).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    : ""
                }}
              </v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
          <!-- Partner Side Navigation -->
          <div
            v-if="
              $vuetify.breakpoint.mdAndDown ||
              !!this.partner ||
              $route.name == 'PartnerDashboard' ||
              $route.name == 'PartnerProfile' ||
              $route.name == 'AgentTree' ||
              $route.name == 'PartnerActivePlayers' ||
              $route.name == 'PartnerActiveAgents'
            "
          >
            <v-divider></v-divider>

            <v-list-item to="/partner/agent-tree">
              <v-list-item-title class="tw-flex tw-items-center">
                <v-icon class="mr-2">mdi-file-tree</v-icon>Agent
                Tree</v-list-item-title
              >
            </v-list-item>
            <v-list-item to="/partner/active-players/">
              <v-list-item-title class="tw-flex tw-items-center">
                <v-icon class="mr-2">mdi-account-check</v-icon>Active
                Players</v-list-item-title
              >
            </v-list-item>
            <!-- <v-list-item to="/partner/active-agents/">
               <v-list-item-title class="tw-flex tw-items-center">
                <v-icon class="mr-2">mdi-account</v-icon>Active Agents</v-list-item-title>
            </v-list-item> -->

            <v-divider></v-divider>
            <!-- <v-list-item
            v-show="is_ogc"
            >
              <v-list-item-content>
                 <router-link
                    to="/partner/dashboard-ogc"
                    ><div
                      class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
                    >
                      <v-icon class="tw-mr-2">mdi-tablet-dashboard</v-icon>Dashboard OGC 
                    </div></router-link
                  >
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item-group>
              <v-list-item @click="openUserDialog" inactive>
                <v-list-item-content>
                  <v-list-item-title class="tw-flex tw-items-center"
                    ><v-icon class="mr-2">mdi-account-plus</v-icon>Add
                    User</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openTransferMoneyDialog" inactive>
                <v-list-item-content>
                  <v-list-item-title class="tw-flex tw-items-center"
                    ><v-icon class="mr-2">mdi-swap-horizontal</v-icon>Transfer
                    Money</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openRegistrationLinkDialog" inactive>
                <v-list-item-content>
                  <v-list-item-title
                    class="tw-flex tw-items-center"
                    v-clipboard="clipboardValue"
                    ><v-icon class="mr-2">mdi-link-plus</v-icon>Registration
                    Link</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="openMessage" inactive>
                <v-list-item-content>
                  <v-list-item-title class="tw-flex tw-items-center"
                    ><v-icon class="mr-2">mdi-message</v-icon
                    >Messages</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <!-- <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="tw-flex tw-items-center" @click="openFbChat"><v-icon class="mr-2">mdi-chat</v-icon>Chat with Us</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-divider></v-divider>
            <div class="mt-5">
              <span class="pr-4"><v-icon>mdi-twitter</v-icon></span>
              <a href="https://www.facebook.com/betgamegg/"
                ><span class="pr-4"><v-icon>mdi-facebook</v-icon></span></a
              >
              <span class="pr-4"><v-icon>mdi-instagram</v-icon></span>
              <span><v-icon>mdi-gmail</v-icon></span>
            </div>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      color="#0D111B"
      overlay-opacity="0.8"
      overlay-color="#000000BF"
      v-model="drawer"
      fixed
      temporary
      dark
      style="z-index: 9999"
      v-if="this.$auth.user && !!this.$auth.user.voucher_code"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <div
              class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
            >
              <v-icon class="mr-5" @click="drawer = !drawer">mdi-menu</v-icon>
              <div>
                <a @click="home"
                  ><v-img
                    width="140"
                    :src="require('@/assets/images/betgame-gg.svg')"
                  ></v-img
                ></a>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-list-item-group v-model="selectedItem">
          <v-list-item class="title">
            <v-list-item-content>
              <v-list-item-title
                @click="adminPage"
                class="tw-flex tw-items-center"
                >STREAMER DASHBOARD</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="item in affiliate_items"
            :key="item.text"
            :to="item.link"
          >
            <v-list-item-content>
              <v-list-item-title class="tw-flex tw-items-center"
                ><v-icon class="mr-2">{{ item.icon }}</v-icon
                >{{ item.text }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item to="/affiliate/active-players">
            <v-list-item-content>
              <v-list-item-title class="tw-flex tw-items-center"
                ><v-icon class="mr-2">mdi-account</v-icon>Active
                Players</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="tw-flex tw-items-center"
                @click="openMessage"
                ><v-icon class="mr-2">mdi-message</v-icon
                >Messages</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <div class="mt-5">
            <span class="pr-4"><v-icon>mdi-twitter</v-icon></span>
            <a href="https://www.facebook.com/betgamegg/"
              ><span class="pr-4"><v-icon>mdi-facebook</v-icon></span></a
            >
            <span class="pr-4"><v-icon>mdi-instagram</v-icon></span>
            <span><v-icon>mdi-gmail</v-icon></span>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      color="#0D111B"
      overlay-opacity="0.8"
      overlay-color="#000000BF"
      v-model="drawer"
      fixed
      temporary
      dark
      style="z-index: 9999"
      v-if="!!is_subuser"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <div
              class="tw-text-white tw-font-light tw-flex tw-items-center tw-mr-10"
            >
              <v-icon class="mr-5" @click="drawer = !drawer">mdi-menu</v-icon>
              <div>
                <a @click="home"
                  ><v-img
                    width="140"
                    :src="require('@/assets/images/betgame-gg.svg')"
                  ></v-img
                ></a>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-list-item-group v-model="subUserSelectedItem">
          <v-list-item class="title">
            <v-list-item-content>
              <v-list-item-title
                @click="adminPage"
                class="tw-flex tw-items-center"
                >SUB USER</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(item, i) in subuser_items"
            :key="i"
            :to="item.link"
          >
            <v-list-item-content>
              <v-list-item-title class="tw-flex tw-items-center"
                ><v-icon class="mr-2">{{ item.icon }}</v-icon
                >{{ item.text }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <div
            v-if="
              $vuetify.breakpoint.mdAndDown ||
              !!$cookies.get('is_subuser') ||
              $route.name == 'SubUserTransactionHistory' ||
              $route.name == 'SubUserActiveAgent' ||
              $route.name == 'SubUserActivePlayers' ||
              $route.name == 'SubUserDashboard'
            "
          >
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="tw-flex tw-items-center"
                  @click="openUserDialog"
                  ><v-icon class="mr-2">mdi-account-plus</v-icon>Add
                  User</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="openSubUserTransferMoneyDialog">
              <v-list-item-content>
                <v-list-item-title class="tw-flex tw-items-center"
                  ><v-icon class="mr-2">mdi-swap-horizontal</v-icon>Transfer
                  Money</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="openRegistrationLinkDialog">
              <v-list-item-content>
                <v-list-item-title
                  class="tw-flex tw-items-center"
                  v-clipboard="clipboardValue"
                  ><v-icon class="mr-2">mdi-link-plus</v-icon>Registration
                  Link</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <!-- <v-list-item >
              <v-list-item-content>
                <v-list-item-title class="tw-flex tw-items-center" @click="openFbChat"><v-icon class="mr-2">mdi-chat</v-icon>Chat with Us</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-divider></v-divider>
            <div class="mt-5">
              <span class="pr-4"><v-icon>mdi-twitter</v-icon></span>
              <a href="https://www.facebook.com/betgamegg/"
                ><span class="pr-4"><v-icon>mdi-facebook</v-icon></span></a
              >
              <span class="pr-4"><v-icon>mdi-instagram</v-icon></span>
              <span><v-icon>mdi-gmail</v-icon></span>
            </div>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <Login />
    <Signup />

    <TypeofUser
      :typeOfUser="type_of_user_dialog"
      :is_subuser="is_subuser"
      @closeDialog="closeAddUserDialog"
    />
    <TransferMoneyUserType
      :transferMoneyUserType="transfer_money_user_type_dialog"
      @closeDialog="closeTransferMoneyDialog"
    />
    <SubUserTransferMoneyUserType
      :transferMoneyUserType="sub_user_transfer_money_user_type_dialog"
      @closeDialog="closeSubUserTransferMoneyDialog"
    />
    <DirectDialog
      :directDialog="direct_dialog"
      @closeDirectDialog="closeDirectDialog"
    />
    <PartnerDialog
      :partnerDialog="this.partner_dialog"
      @closePartnerDialog="closePartnerDialog"
    />
    <RegistrationLinkDialog
      :clipboardValue="clipboardValue"
      :registrationDialog="registration_link_dialog"
      @closeDialog="closeRegistrationLinkDialog"
    />
  </div>
</template>

<script>
import io from "socket.io-client";
import { mapActions, mapMutations, mapState } from "vuex";
import Login from "./auth/Login.vue";
import Signup from "./auth/Signup.vue";
import TypeofUser from "../components/partner/dashboard/modal/TypeofUser.vue";
import TransferMoneyUserType from "../components/partner/dashboard/modal/TransferMoneyUserType.vue";
import SubUserTransferMoneyUserType from "../components/partner/sub-user/dashboard/TransferMoneyUserType.vue";
import DirectDialog from "../components/profile/DepositWithdrawTab/modal/DirectEvoucher.vue";
import PartnerDialog from "../components/profile/DepositWithdrawTab/modal/PartnerDialog.vue";
import RegistrationLinkDialog from "../components/partner/dashboard/modal/RegistrationLink.vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
export default {
  components: {
    Login,
    Signup,
    TypeofUser,
    TransferMoneyUserType,
    DirectDialog,
    PartnerDialog,
    SubUserTransferMoneyUserType,
    RegistrationLinkDialog,
  },
  data() {
    return {
      drawer: null,
      bettordrawer: null,
      selectedItem: 1,
      subUserSelectedItem: 0,
      // user: this.$session.get("user"),
      partner: "",
      items: [
        { text: "Esports", icon: "mdi-controller-classic" },
        { text: "Esport News", icon: "mdi-newspaper" },
        { text: "Help", icon: "mdi-help-circle-outline" },
        { text: "Partner Dashboard", icon: "mdi-help-circle-outline" },
      ],
      partner_items: [
        {
          text: "Dashboard",
          icon: "mdi-view-dashboard",
          link: "/partner/dashboard",
          show_credits: false,
        },
        // { text: 'Transactions History', icon: 'mdi-newspaper' },
        // { text: 'Payout', icon: 'mdi-help-circle-outline' },
        {
          text: "Partner Profile",
          icon: "mdi-account",
          link: "/partner/profile",
          show_credits: false,
        },
        // {
        //   text: "Partner Credits",
        //   icon: "mdi-wallet",
        //   show_credits : true
        // },
      ],
      affiliate_items: [
        { text: "Dashboard", icon: "mdi-view-dashboard", link: "/affiliate" },
        // { text: 'Transactions History', icon: 'mdi-newspaper' },
        // { text: 'Payout', icon: 'mdi-help-circle-outline' },
      ],
      subuser_items: [
        {
          text: "Partner Dashboard",
          icon: "mdi-view-dashboard",
          link: "/partner/sub-user/dashboard",
        },
        {
          text: "Partner Transaction",
          icon: "mdi-account",
          link: "/partner/sub-user",
        },
        // { text: 'Transactions History', icon: 'mdi-newspaper' },
        // { text: 'Payout', icon: 'mdi-help-circle-outline' },
      ],
      user_items: [
        {
          text: "HOME",
          icon: "mdi-home",
          href: "/?bt-path=%2F",
        },
        {
          text: "CASINO",
          icon: "mdi-slot-machine",
          href: "/casino",
        },
        {
          text: "LIVE",
          icon: "mdi-broadcast",
          href: "/?bt-path=%2Flive",
        },
        {
          text: "ESPORTS",
          icon: "mdi-gamepad-variant",
          href: "/?bt-path=%2Fe_sport",
        },
        {
          text: "SPORTS",
          icon: "mdi-basketball",
          href: "%2F2%2F1669819088232386560%2F1669819088278523904",
          subheader: [
            {
              name: "NBA",
              link: "%2F2%2F1669819088232386560%2F1669819088278523904",
            },
            {
              name: "BasketBall",
              link: "%2F2",
            },
            {
              name: "MMA",
              link: "%2F117",
            },
            {
              name: "Soccer",
              link: "%2F1",
            },
          ],
        },
        {
          text: "FAVORITES",
          icon: "mdi-star",
          href: "/?bt-path=%2Ffavorites",
        },
        {
          text: "BET HISTORY (SPORTBOOK)",
          icon: "mdi-receipt",
          href: "/?bt-path=%2Fbets",
        },
        {
          text: "SEARCH",
          icon: "mdi-magnify",
          href: "/?bt-path=%2Fsearch",
        },
      ],
      nestedListGroupOpen: false,
      user_info: {},
      type_of_user_dialog: false,
      transfer_money_user_type_dialog: false,
      sub_user_transfer_money_user_type_dialog: false,
      registration_link_dialog: false,
      clipboardValue: "",
      link: false,
      partner_route: "",
      direct_dialog: false,
      partner_dialog: false,
      partnerQuery: "",
      userCredits: 0,
      partnerCredits: 0,
      loading: true,
      is_subuser: "",
      tab: null,
      sports: [
        {
          name: "NBA",
          link: "%2F2%2F1666080250473353216%2F1666080250586599424",
        },
        {
          name: "BasketBall",
          link: "%2F2",
        },
        {
          name: "MMA",
          link: "%2F117",
        },
        {
          name: "Soccer",
          link: "%2F1",
        },
      ],
      unreadmessage: 0,
      subUserPartnerCredits: 0,
      sub_user: "",
      // is_ogc: "",
    };
  },

  methods: {
    ...mapActions("user", [
      "logout",
      "getUserInfo",
      "userValidate",
      "getMessage",
    ]),
    ...mapActions("partner", ["getPartnerData"]),
    ...mapActions("user", ["getSubUser", "socketAction"]),
    ...mapMutations("slotegator", [
      "SET_SHOW_LOGIN",
      "SET_SHOW_REGISTER",
      "SET_SHOW_DEPOSIT",
      "SET_SHOW_PARTNER_DEPOSIT",
    ]),
    ...mapMutations("user", ["setSocketMutation"]),
    ...mapMutations("app", ["SET_SHOW_FB_CHAT"]),
    async userInfo() {
      if (this.$auth.user) {
        await this.getUserInfo(this.$auth.user.id);
        this.user_info = this.auth_user;
      }
    },
    async logoutUser() {
      this.$cookies.remove("_token");
      await this.logout();
    },
    profile() {
      console.log("xxxxxxxxx");
      if (this.$route.name != "PartnerDashboard") {
        if (this.$route.path != "/profile") {
          this.$router.push("/profile").catch(() => {});
        }
      } else {
        if (this.$route.path != "/partner/profile") {
          this.$router.push("/partner/profile").catch(() => {});
        }
      }
    },
    message() {
      this.$router.push("/messages").catch(() => {});
    },
    home() {
      if (this.$route.path != "/") {
        this.$router.push("/").catch(() => {});
      }
    },
    adminPage() {
      if (this.$route.path != "/admin") {
        this.$router.push("/admin").catch(() => {});
      }
    },
    showLogin() {
      console.log("LGOIN");
      this.SET_SHOW_REGISTER(false);
      this.SET_SHOW_LOGIN(true);
    },
    showRegister() {
      this.SET_SHOW_LOGIN(false);
      this.SET_SHOW_REGISTER(true);
    },
    openUserDialog() {
      this.type_of_user_dialog = true;
    },
    openFbChat() {
      if (this.$route.path != "/profile") {
        this.$router.push("/profile").catch(() => {});
      }
    },
    closeAddUserDialog() {
      this.type_of_user_dialog = false;
    },
    openTransferMoneyDialog() {
      this.transfer_money_user_type_dialog = true;
    },
    openRegistrationLinkDialog() {
      this.registration_link_dialog = true;
    },
    closeRegistrationLinkDialog() {
      this.registration_link_dialog = false;
    },
    openSubUserTransferMoneyDialog() {
      this.sub_user_transfer_money_user_type_dialog = true;
      console.log("LOG", this.sub_user_transfer_money_user_type_dialog);
    },
    closeTransferMoneyDialog() {
      this.transfer_money_user_type_dialog = false;
    },
    closeSubUserTransferMoneyDialog() {
      this.sub_user_transfer_money_user_type_dialog = false;
      console.log("LOG", this.sub_user_transfer_money_user_type_dialog);
    },
    copyLink() {
      console.log("linkkkked", process.env.VUE_APP_REGISTRATION_LINK);
      this.$toast.success("Registration Link copied");
    },
    openMessage() {
      if (this.$route.path != "/messages") {
        this.$router.push("/messages").catch(() => {});
      }
    },
    async getPartnerRoute(value = null) {
      if (value) {
        this.SET_SHOW_REGISTER(true);
      }
    },
    async showVoucherReg(value = null) {
      if (value) {
        this.SET_SHOW_REGISTER(true);
      }
    },
    openDirectDialog() {
      console.log("asdad");
      this.direct_dialog = true;
      this.SET_SHOW_DEPOSIT(true);
    },
    closeDirectDialog() {
      this.direct_dialog = false;
      this.SET_SHOW_DEPOSIT(false);
    },
    openPartnerDialog() {
      this.partner_dialog = true;
      this.SET_SHOW_PARTNER_DEPOSIT(true);
    },
    closePartnerDialog() {
      this.partner_dialog = false;
      this.SET_SHOW_PARTNER_DEPOSIT(false);
    },
    FrequentlyAskedQuestions() {
      if (this.$route.path != "/faqs") {
        this.$router.push("/faqs").catch(() => {});
      }
    },
    ResponsibleGamblingPolicy() {
      if (this.$route.path != "/responsible-gambling-policy") {
        this.$router.push("/responsible-gambling-policy").catch(() => {});
      }
    },
    navTo(tab) {
      if (this.bt_reader) this.bt_reader.updateOptions({ url: tab });
    },
    asd() {
      switch (this.$route.query["bt-path"]) {
        case "/":
          this.tab = "home";
          break;
        case "/live":
          this.tab = "live";
          break;
        case "/e_sport":
        case "/109":
        case "/110":
          this.tab = "e_sport";
          break;
        default:
          this.tab = "home";
      }
    },
    goTo(link) {
      this.$router.push(`/?bt-path=${link}`);
    },
    async getMessageNotif() {
      if (!!this.$auth.user) {
        const response = await this.getMessage({
          page: 1,
          limit: 1,
          search: "",
          id: this.$auth.user.id,
        });
        this.unreadmessage = response.unread;
      }
    },
  },
  computed: {
    ...mapState("user", ["auth_user", "socket"]),
    ...mapState("slotegator", ["bt_reader"]),
    ...mapState("app", ["showFbChat"]),
    currentRouteName() {
      return this.$route.name;
    },
    total() {
      let credits = this.$auth.user && parseInt(this.$auth.user.credits);
      let partner_credits =
        this.partner && parseInt(this.partner.partner_credits);
      let sum = credits + partner_credits;
      return sum;
    },
    isMobile() {
      return useBreakpoints(breakpointsTailwind).smallerOrEqual("sm");
    },
    getUserMessageData() {
      return this.unreadmessage ? this.unreadmessage : 0;
    },
    hasSubheader() {
      return this.user_items.some(
        (item) => item.text === "SPORTS" && item.subheader
      );
    },
  },
  async mounted() {
    if (this.socket) {
      const that = this;
      this.socket.on("receiveMail", (data) => {
        console.log("DATA OF RECEIVE EMAIL", data);
        if (data.id) {
          that.unreadmessage += 1;
        }
      });
    }
    await this.userValidate();
    this.is_subuser = this.$cookies.get("is_subuser");
    this.userCredits = this.$auth.user && this.$auth.user.credits;
    // this.is_ogc = this.partner.id == process.env.VUE_APP_OGC_ID ? true : false;
    // console.log("OGCCC",this.is_ogc)
    let partner_id = this.$cookies.get("partner_id");
    if (this.is_subuser) {
      const res = await this.getSubUser(this.$auth.user.id);
      this.sub_user = res;
      this.subUserPartnerCredits = res && res.partner_credits;
      console.log("RESSSS", this.subUserPartnerCredits);
      const response = await this.$authApi.post(
        `/partner/sub-user/getlink/${res.user_id}`
      );
      if (response && response.data) {
        this.clipboardValue = response.data.link;
      }
    }
    // console.log("HEADER RESPONSE",response)
    // this.clipboardValue = `${process.env.VUE_APP_SOCKET_IO_PORT}?partner=${partner_id}`;
    if (parseInt(partner_id)) {
      const response = await this.$authApi.post("/partner/getlink");
      console.log("HEADER RESPONSE", response);
      this.clipboardValue = response.data.link;
    }
    this.partnerCredits = this.partner && this.partner.partner_credits;

    const that = this;
    if (this.$auth.user && this.socket) {
      this.socket.on("updatedCredits", (data) => {
        that.userCredits = data;
        that.loading = false;
        console.log("hey hey hery updated", data);
      });

      if (!!partner_id) {
        this.socket.on("updatedPartnerCredits", (data) => {
          that.partnerCredits = data;
          that.loading = false;
          console.log("hey hey hery updated partner credits", data);
        });
      }
    }
    this.asd();
  },
  async created() {
    if (this.$auth.user) {
      let socket = io(`${process.env.VUE_APP_SOCKET_IO_PORT}`, {
        reconnectionDelayMax: 10000,
        query: {
          userID: this.$auth.user.id,
        },
      });
      // console.log("set stocket",socket)
      // this.SET_SOCKET(socket);
      this.socketAction(socket);
      this.userCredits = this.$auth.user && this.$auth.user.credits;

      // console.log("socket?", this.socket);
      // this.user = this.$auth.user != null ? true : false;
      this.partner = await this.getPartnerData(this.$auth.user.id);
      this.partnerCredits = this.partner && this.partner.partner_credits;
    }
    this.userInfo();
    const unwatch = this.$watch(
      () => this.$route,
      (route, prevRoute) => {
        this.partnerQuery = route.query.partner;
        this.getPartnerRoute(this.partnerQuery);
        let voucherCode = route.query.voucher;
        this.showVoucherReg(voucherCode);
        unwatch();
      }
    );
    await this.getMessageNotif();
  },
  watch: {
    "$route.query": function () {
      this.asd();
    },
  },
};
</script>
<style scoped>
.loading {
  width: 20px;
  height: 20px;
  background: transparent;
}
.divider {
  border-color: hsl(0deg 0% 100%) !important;
  transform: rotateX(45deg);
  height: 68px;
}
.amounts {
  width: 141%;
}
.title {
  border-bottom: 4px solid #ffffff;
}
.dropdown {
  background: linear-gradient(140.61deg, #0b1638 0%, #39351a 100%) !important;
}
.facebook-link {
  color: white;
  /* Optionally, you can add additional styles like text-decoration: none; to remove underline */
  text-decoration: underline;
}
</style>
