<template>
  <v-dialog v-model="viewDialog" width="700" @keydown.esc="closeDialog" @click:outside="closeDialog">
    <v-card class="add-user">
        <div class="tw-text-right tw-pr-4 tw-pt-3">
          <v-icon @click="closeDialog" color="red">mdi-close</v-icon>
        </div>
        <v-card-title class="text-h6 d-flex justify-center py-2 pb-0">
          Add User 
        </v-card-title>
        <!-- For now admin add sub user isn't available -->
          <!-- <v-card-actions class="d-flex justify-space-around py-10" v-if="is_subuser">
            <v-btn color="#45D0D2" depressed outlined rounded x-large @click="openPlayerDialog"> PLAYER</v-btn>
          </v-card-actions> -->
          <!-- <v-card-actions class="d-flex justify-space-around py-10" v-else> -->

          <div :class="`tw-p-5 tw-py-14 tw-grid ${partnerprofile && partnerprofile.type != 'agent' ? 'md:tw-grid-cols-2' : 'md:tw-grid-cols-1 md:tw-px-60'} tw-grid-cols-1 tw-gap-4`">
            <v-btn class="px-5" color="#45D0D2" v-if="partnerprofile && partnerprofile.type != 'agent'" depressed outlined rounded large @click="openAgentDialog"> AGENT </v-btn>
            <v-btn class="px-5" color="#45D0D2" depressed outlined rounded large @click="openPlayerDialog"> PLAYER </v-btn>
          </div>
    </v-card>
    <AddUserDialog :addUserDialog="add_user_dialog" @closeDialog="closeAlertDialog"/>
    <AddAgentDialog v-if="partnerprofile && partnerprofile.type != 'agent'" :addAgentDialog="add_agent_dialog" @closeDialog="closeAlertDialog"/>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AddUserDialog from "../modal/AddUserDialog.vue";
import AddAgentDialog from "../modal/AddAgentDialog.vue";
export default {
  props: ["typeOfUser","is_subuser"],
  data() {
    return {
      alert_dialog: false,
      add_user_dialog: false,
      add_agent_dialog: false,
    };
  },
  components: {
    AddUserDialog,
    AddAgentDialog
  },
  computed: {
    ...mapState("partner", ["partnerprofile", "adduser"]),
    viewDialog() {
      return this.typeOfUser;
    },
  },
  methods: {
    ...mapActions("partner", ["getPartnerProfileData", "postAddUser"]),
    openAgentDialog(){
      this.add_agent_dialog = true;
    },
    openPlayerDialog(){
      this.add_user_dialog = true;
    },
    closeDialog() {
      console.log("TESSSST")
      this.$emit("closeDialog", true);
    },
    closeAlertDialog() {
      this.add_agent_dialog = false;
      this.add_user_dialog = false;
      this.alert_dialog = false;
    },
  },
  async mounted() {},
};
</script>
<style>
.add-user{
  background: #0D111B !important;
  border-radius: 20px !important;
}.add-user-input{
  border-radius: 5px;
  border: 0;
  background: #222F3A;
}
</style>