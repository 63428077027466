const state = {
  getusers: null,
  adminpartnerdeposittransactions: null,
  adminpartnercashouttransactions: null,
  adminusersdeposittransactions: null,
  adminuserscashouttransactions: null,
  transactiondiscrepancies : null,
  adminpartnerlist : null,
  adminpartneraffiliates : null,
  adminpartnersubusers : null,
  adminpartnersubpartners : null,
  adminpartneradmindeposit : null,
  adminpartneradmincashout : null,
  siteSettings: null,
  getbadges: null,
  partnerpayoutadmin: null,
  generatepayoutpartner: null,
  adminaffiliate: null,
  affiliatepayoutdata: null,
  generatepayoutaffiliate: null,
  partnerpayout: null,
  partnerpayoutbulk: null,
  generatepayoutbulk: null,
  downloadexcelpayoutbulk: null,
  affiliatepayout: null,
  marketingusers: null,
  googlesheets: null,
  affiliatecommissionlist: null,
  adminactions: null,
  adminlist: null,
  partnerapprovallist: null,
  partnerpayoutlist: null,
};

export default state;
